<template>
    <div class="col-md-5 mx-0 my-3">
      <form @submit.prevent="startExport">
      <div class="form-group">
        <div class="row">
          <label class="col-form-label col-sm-3 pt-0">Marketo Object</label>
          <div class="col-sm-9">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="object_name" id="email_object" value="Email" v-model="objectName" required>
              <label class="form-check-label" for="email_object">
                &nbsp;Email
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="object_name" id="email_template_object" value="Email Template" v-model="objectName" required>
              <label class="form-check-label" for="email_template_object">
                &nbsp;Email Template
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="object_name" id="folder_object" value="Folder" v-model="objectName" required>
              <label class="form-check-label" for="folder_object">
                &nbsp;Folder
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="object_name" id="form_object" value="Form" v-model="objectName" required>
              <label class="form-check-label" for="form_object">
                &nbsp;Form
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="object_name" id="file_object" value="File" v-model="objectName" required>
              <label class="form-check-label" for="file_object">
                &nbsp;Images &amp; Files
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="object_name" id="lp_object" value="Landing Page" v-model="objectName" required>
              <label class="form-check-label" for="lp_object">
                &nbsp;Landing Page
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="object_name" id="lp_template_object" value="Landing Page Template" v-model="objectName" required>
              <label class="form-check-label" for="lp_template_object">
                &nbsp;Landing Page Template
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="object_name" id="list_object" value="List" v-model="objectName" required>
              <label class="form-check-label" for="list_object">
                &nbsp;List
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="object_name" id="program_object" value="Program" v-model="objectName" required>
              <label class="form-check-label" for="program_object">
                &nbsp;Program
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="object_name" id="smart_campaign_object" value="Smart Campaign" v-model="objectName" required>
              <label class="form-check-label" for="smart_campaign_object">
                &nbsp;Smart Campaign
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="object_name" id="smart_list_object" value="Smart List" v-model="objectName" required>
              <label class="form-check-label" for="smart_list_object">
                &nbsp;Smart List
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="object_name" id="snippet_object" value="Snippet" v-model="objectName" required>
              <label class="form-check-label" for="snippet_object">
                &nbsp;Snippet
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row  mt-3">
        <div class="col-sm-10">
          <button type="submit" class="btn btn-primary" v-bind:disabled="disableButton">Start Export</button>
        </div>
        <div v-if="munchkinOrTokenInvalid" class="text-danger mt-2">Please enter a correctly formatted Munchkin ID and Access Token above or sign in</div>
        <div v-if="noInstanceSelected" class="text-danger mt-2">Please select a Marketo instance (or add your first Marketo Instance)</div>
      </div>
      
      <div class="form-group row" v-if="disableButton">
        <div class="col-sm-10">
          <a @click="cancelExport" style="text-decoration: underline; cursor: pointer;">Cancel Export</a>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  emits: ['start-export'],
  data() {
        return {
            disableButton: false,
            objectName: '',
            munchkinOrTokenInvalid: false,
            noInstanceSelected: false
        }
  },
  computed: {
      munchkinId() {
          return this.$store.getters['munchkinId']
      },
      accessToken() {
          return this.$store.getters['accessToken']
      },
      munchkinIdIsValid() {
          return this.$store.getters['munchkinIdIsValid']
      },
      accessTokenIsValid() {
          return this.$store.getters['accessTokenIsValid']
      },
      selectedInstanceId() {
          return this.$store.getters['selectedInstance']
      },
      user() {
          return this.$store.getters['user']
      }
  },
  methods: {
    startExport() {
      if (!this.user) {
        if (this.munchkinIdIsValid && this.accessTokenIsValid){
          this.munchkinOrTokenInvalid = false
          this.disableButton = true
          this.$emit('start-export', this.munchkinId, this.accessToken, this.objectName, null)
        } else {
          this.munchkinOrTokenInvalid = true
        }
      } else {
        if (this.selectedInstanceId) {
          this.noInstanceSelected = false
          this.disableButton = true
          this.$emit('start-export', null, null, this.objectName, this.selectedInstanceId)
        } else {
          this.noInstanceSelected = true
        }
      }
    },
    cancelExport() {
      this.disableButton = false
    }
  }
}
</script>