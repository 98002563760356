<template>
    <div class="row mx-0 my-3">
        <email-diff-controls @start-diff="startDiff" ref='diffControls' :mkto-email-id='id'></email-diff-controls>
        <email-diff-results @diff-complete="enableButton" ref="diffResults"></email-diff-results>
    </div>
</template>

<script>
import EmailDiffControls from './EmailDiffControls.vue'
import EmailDiffResults from './EmailDiffResults.vue'

export default {
    name: "emaildiff",
    props: ['id'],
    components: {
        EmailDiffControls, 
        EmailDiffResults
    },
    data() {
        return {
            munchkinId: '',
            accessToken: '', 
            emailId: '',
            instanceId: ''
        }
    },
    methods: {
        async startDiff(enteredMunchkinId, enteredAccessToken, enteredEmailId, enteredInstanceId) {
            this.munchkinId = enteredMunchkinId
            this.accessToken = enteredAccessToken
            this.emailId = enteredEmailId
            this.instanceId = enteredInstanceId
            const token = await this.$store.dispatch('requestRecaptchaToken')
            this.$refs.diffResults.startJob(this.munchkinId, this.accessToken, this.emailId, token, this.instanceId)
        },
        enableButton() {
            this.$refs.diffControls.cancelExport()
        }
    }
}
</script>
