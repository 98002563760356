<template>
    <div v-if="marketoInstances" class="row pe-0">
        <div class="col align-middle" style="color: rgba(255,255,255,.55);">
            <p class="text-end mt-1">Marketo Instance: </p>
        </div>
        <div class="col ps-0">
            <select v-model="selected" class="form-select" >
                <option v-for="option in marketoInstances" :value="option.id" :key="option.id">
                    {{ option.name }}
                </option>
            </select>
        </div>
    </div>
    <router-link v-else to="/instances" style="color:white;" class="row bg-primary py-3 m-0"><u>Add Marketo Instance</u></router-link>
</template>

<script>
export default {
    computed: {
        marketoInstances() {
            return this.$store.getters['marketoInstances']
        },
        instanceLoadComplete() {
            return this.$store.getters['instanceLoadComplete']
        },
        selected: {
            get () {
                return this.$store.getters['selectedInstance']
            },
            set (value) {
                this.$store.commit('saveSelectedInstance', value)
            }
        }
    },
    watch: {
        instanceLoadComplete(newValue, oldValue) {
            if (newValue && !oldValue && this.marketoInstances.length === 0) {
                this.$router.push('/instances')
            } else if (!this.selected) {
                // to cover an edge case where the Instance ID in the cookie is for a different user
                this.selected = this.marketoInstances[0]['id']
            }
        }
    } 
}
</script>