import { createRouter, createWebHistory } from 'vue-router';
import { Auth } from 'aws-amplify'

import AssetExport from '../pages/assetExport/AssetExportContainer.vue'
import EmailDiff from '../pages/emailDiff/EmailDiffContainer.vue'
import ApiUsage from '../pages/apiUsage/ApiUsageContainer.vue'
import SignIn from '../pages/SignIn.vue'
import Faq from '../pages/about/Faq.vue'
import ManageInstances from '../pages/manageInstances/ManageInstances.vue'
import NotFound from '../pages/NotFound.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', redirect: '/apiusage' },
    { path: '/assetexport', component: AssetExport },
    { path: '/emaildiff', component: EmailDiff },
    {
      path: '/emaildiff/:id',
      component: EmailDiff,
      props: true
    },
    { path: '/apiusage', component: ApiUsage },
    { path: '/signin', component: SignIn },
    { path: '/faq', component: Faq },
    { path: '/instances', component: ManageInstances, meta: { requiresAuth: true} },
    { path: '/:notFound(.*)', component: NotFound }
  ]
});

router.beforeResolve(async to => {
  if (to.meta.requiresAuth) {
    try {
      await Auth.currentAuthenticatedUser()
    } catch (e) {
      router.push('/signin')
    }
  }
})

export default router;
