<template>
    <div class="col-md-4 ms-0 me-2 my-3" >
      <form @submit.prevent="startExport">
      <div class="form-group">
        <div class="row">
          <label class="col-form-label col-sm-3 pt-0" for="email_object">Email Id</label>
          <div class="col-sm-9">
            <div class="form-check">
              <input class="form-control" type="number" name="email_id" id="email_id" v-model.trim="emailId" required>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
          <div class="col my-4">
              <em>The "Diff" will compare the draft version with the approved version. </em>
          </div>
      </div>
      <div class="form-group row">
        <div class="col">
          <button type="submit" class="btn btn-primary" v-bind:disabled="disableButton">Start Diff</button>
          <div v-if="munchkinOrTokenInvalid" class="text-danger mt-2">Please enter a correctly formatted Munchkin ID and Access Token above or sign in</div>
          <div v-if="noInstanceSelected" class="text-danger mt-2">Please select a Marketo instance (or add your first Marketo Instance)</div>
        </div>
      </div>
      <div class="form-group row" v-if="disableButton">
        <div class="col">
          <a @click="cancelExport" style="text-decoration: underline; cursor: pointer;">Cancel Diff</a>
        </div>
      </div>
      <div class="row">
        <div class="col my-4 p-3 shadow rounded">
          <h4>Bookmarklet</h4>
          Install this bookmarklet to launch Email Diff from within Marketo. Select an Email, 
          click the Bookmarklet and Email Diff will open with the Email ID prepopulated. <br/><br/>
          To install, drag this link to your bookmarks bar: <br/>
          <a href="javascript:(function(){  let url_split = window.location.href.split('/');let asset = url_split[url_split.length - 1];if (asset.slice(0, 2) === 'EM') {let asset_sliced = asset.slice(2);let email_id = asset_sliced.match(/^(\d+)/g);window.open('https://www.dataketo.com/emaildiff/' + email_id);} else {console.log('no match');};})();">Email Diff</a>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: ['mktoEmailId'],
  emits: ['start-diff'],
  data() {
        return {
            disableButton: false,
            emailId: '',
            munchkinOrTokenInvalid: false,
            noInstanceSelected: false
        }
  },
  computed: {
      munchkinId() {
          return this.$store.getters['munchkinId']
      },
      accessToken() {
          return this.$store.getters['accessToken']
      },
      munchkinIdIsValid() {
          return this.$store.getters['munchkinIdIsValid']
      },
      accessTokenIsValid() {
          return this.$store.getters['accessTokenIsValid']
      },
      selectedInstanceId() {
          return this.$store.getters['selectedInstance']
      },
      user() {
          return this.$store.getters['user']
      }
  },
  methods: {
    startExport() {
      if (!this.user) {
        if (this.munchkinIdIsValid && this.accessTokenIsValid){
          this.munchkinOrTokenInvalid = false
          this.disableButton = true
          this.$emit('start-diff', this.munchkinId, this.accessToken, this.emailId, null)
        } else {
          this.munchkinOrTokenInvalid = true
        }
      } else {
        if (this.selectedInstanceId) {
          this.noInstanceSelected = false
          this.disableButton = true
          this.$emit('start-diff', null, null, this.emailId, this.selectedInstanceId)
        } else {
          this.noInstanceSelected = true
        }
      }
    },
    cancelExport() {
      this.disableButton = false
    }
  },
  created() {
    if (this.mktoEmailId){
      this.emailId = this.mktoEmailId  // if the email id is passed in via the URL, populate it
      this.startExport()
    } 
  }
}
</script>