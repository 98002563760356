<template>
    <div class="col-md m-3">
        <div v-if="emailId" class="row" style="background-color: #d3d3d3; padding: 15px; border-radius: 5px;">
            <h3 style="margin-top: 5px;">Diff of Email {{ emailId }} 
                <span v-if="!diffDone">in Progress</span>
                <span v-if="diffDone && !errorMessage">Complete</span>
            </h3>
            <div v-if="!diffDone" class="spinner-border text-secondary m-3" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <div v-if="diffDone && !errorMessage" style="margin-top: 10px;">
                <table class="table">
                    <thead>
                    <tr>
                        <th scope="col">&nbsp;</th>
                        <th scope="col">Approved Email</th>
                        <th scope="col">Draft Email</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th scope="row">Subject: </th>
                        <td>{{diffResult.subjectApproved}}</td>
                        <td :class="diffResult.subjectSame ? 'table-success' : 'table-danger'">{{diffResult.subjectDraft}}</td>
                    </tr>
                    <tr>
                        <th scope="row">From Name: </th>
                        <td>{{diffResult.fromNameApproved}}</td>
                        <td :class="diffResult.fromNameSame ? 'table-success' : 'table-danger'">{{diffResult.fromNameDraft}}</td>
                    </tr>
                    <tr>
                        <th scope="row">From Email: </th>
                        <td>{{diffResult.fromEmailApproved}}</td>
                        <td :class="diffResult.fromEmailSame ? 'table-success' : 'table-danger'">{{diffResult.fromEmailDraft}}</td>
                    </tr>
                    <tr>
                        <th scope="row">Reply-to: </th>
                        <td>{{diffResult.replyEmailApproved}}</td>
                        <td :class="diffResult.replyEmailSame ? 'table-success' : 'table-danger'">{{diffResult.replyEmailDraft}}</td>
                    </tr>
                    <tr>
                        <th scope="row">Preheader: </th>
                        <td>{{diffResult.preHeaderApproved}}</td>
                        <td :class="diffResult.preHeaderSame ? 'table-success' : 'table-danger'">{{diffResult.preHeaderDraft}}</td>
                    </tr>
                    <tr>
                        <th scope="row">Body HTML:</th>
                        <td>
                            <a v-if="htmlDiffUrl" :href="htmlDiffUrl">Click to download diff</a>
                        </td>
                        <td :class="htmlDiffUrl ? 'table-danger' : 'table-success'">
                            <span v-if="htmlDiffUrl">different</span><span v-else>same</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div v-if="errorMessage" style="color:red;">{{ errorMessage }}</div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { Auth } from 'aws-amplify'

export default {
    emits: ['diff-complete'],
    data() {
      return {
        errorMessage: '',
        munchkinId: '',
        accessToken: '',
        emailId: '',
        instanceId: '',
        diffDone: false,
        diffResult: {},
        htmlDiffUrl: ''
      }
    },
    methods: {
        async startJob(munchkinId, accessToken, emailId, token, instanceId) {
            this.diffDone = false
            this.munchkin_id = munchkinId
            this.accessToken = accessToken
            this.emailId = emailId
            this.instanceId = instanceId
            this.errorMessage = ''  // this will remove any error messages from prior attempts
            const keyArray = ['subject', 'fromName', 'fromEmail', 'replyEmail', 'preHeader']
            let pathPostfix = ''
            let axiosConfig
            let payload
            if (!this.instanceId) {
                payload = {
                    "munchkin_id": munchkinId, 
                    "access_token": accessToken,
                    "email_id": parseInt(emailId),
                    "recaptcha_token": token
                }
            } else {
                pathPostfix = '_auth'
                const idToken = (await Auth.currentSession()).getIdToken().getJwtToken()
                axiosConfig = {
                    headers: {authorization: idToken}
                };
                payload = {
                    "email_id": parseInt(emailId),
                    "recaptcha_token": token,
                    "instance_id": instanceId
                }
            }
            axios.post('https://' + process.env.VUE_APP_API_GATEWAY + '.execute-api.us-east-2.amazonaws.com/emaildiff' + pathPostfix, payload, axiosConfig)
                .then(res => {
                    console.log(res)
                    this.diffDone = true
                    if(res.data.success) {
                        let diffResponse = res.data.email_diff
                        console.log(this.diffResult)
                        this.enableButton()
                        for (let key of keyArray) {
                            if (diffResponse[key].same) {
                                this.diffResult[key + 'Same'] = true
                                this.diffResult[key + 'Approved'] = diffResponse[key].approved
                                this.diffResult[key + 'Draft'] = 'same'
                            } else {
                                this.diffResult[key + 'Same'] = false
                                this.diffResult[key + 'Approved'] = diffResponse[key].approved
                                this.diffResult[key + 'Draft'] = diffResponse[key].draft
                            }
                        }
                        this.htmlDiffUrl = diffResponse.bodyHtml.diffUrl
                    } else {
                        console.log('error: ' + res.data.error)
                        this.enableButton()
                        this.errorMessage = res.data.error
                    }                
                })
                .catch(error => console.log(error))
        },
        enableButton() {
            this.$emit('diff-complete')
        }
    }
}
</script>