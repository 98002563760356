import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';

Amplify.configure(aws_exports);
applyPolyfills().then(() => {
  defineCustomElements(window);
});

import { createApp } from 'vue'

import router from './router/router.js'
import store from './store/store.js'
import App from './App.vue'
import BaseButton from './components/ui/BaseButton.vue'

const app = createApp(App)

app.use(router)
app.use(store)

app.component('base-button', BaseButton);

app.mount('#app')
