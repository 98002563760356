<template>
    <div class="row mx-0 my-3">
        <asset-export-controls @start-export="startExportJob" ref='exportFrm'></asset-export-controls>
        <asset-export-results @export-complete="enableExportButton" ref="exportMgr"></asset-export-results>
    </div>
</template>

<script>
import AssetExportControls from './AssetExportControls.vue'
import AssetExportResults from './AssetExportResults.vue'

export default {
    name: "assetexport",
    components: {
        AssetExportControls, 
        AssetExportResults
    },
    data() {
        return {
            munchkinId: '',
            accessToken: '', 
            objectName: '',
            instanceId: ''
        }
    },
    methods: {
        async startExportJob(enteredMunchkinId, enteredAccessToken, enteredObjectName, enteredInstanceId) {
            this.munchkinId = enteredMunchkinId
            this.accessToken = enteredAccessToken
            this.objectName = enteredObjectName
            this.instanceId = enteredInstanceId
            const token = await this.$store.dispatch('requestRecaptchaToken')
            this.$refs.exportMgr.startJob(this.munchkinId, this.accessToken, this.objectName, token, this.instanceId)
        },
        enableExportButton() {
            this.$refs.exportFrm.cancelExport()
        }
    }
}
</script>
