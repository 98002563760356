<template>
  <div class="container">
    <div class="row bg-primary">
      <div class="row m-1">
        <div class="col-md-3 m-1 mt-3">
          <h1>
            <router-link to="/">Dataketo</router-link>
          </h1>
          <p class="mt-2" style="color: rgba(255,255,255,.55);">Power apps for Marketo</p>
        </div>
        <div class="col m-1">
          <div class="row m-1">
            <!-- p class="text-end"><a href="#" style="color: white; text-decoration: none;">Sign In / Sign Up</a></p -->
            <nav class="navbar navbar-expand navbar-dark bg-primary">
              <div class="collapse navbar-collapse w-100" id="navbar2">
                <ul class="navbar-nav ms-auto">
                  <li class="nav-item">
                    <router-link to="/faq" class="nav-link" :class="{active: selectedPath === '/faq'}">FAQ &amp; Help</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link v-if="authState === 'signedin'" to="/instances" class="nav-link" 
                      :class="{active: selectedPath === '/instances'}">Manage Marketo Instances</router-link>
                  </li>
                  <li v-if="authState !== 'signedin'" class="nav-item">
                    <router-link to="/signin" class="nav-link" :class="{active: selectedPath === '/signin'}">Sign In/Sign Up</router-link>
                  </li>
                  <li class="nav-item dropdown" v-if="authState === 'signedin' && user">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      {{user.attributes.email}}
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                      <li><a @click="signOut" class="dropdown-item" href="#">Sign Out</a></li>
                    </ul>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
          <div class="row m-0 w-100">
            <the-access-token v-if="selectedPath !== '/signin' && authState !== 'signedin'" class="ms-auto"></the-access-token>
            <the-instance-dropdown v-else-if="authState === 'signedin'"></the-instance-dropdown>
          </div>
        </div>
      </div>
      <div class="row m-1 ms-0">
        <nav class="navbar navbar-expand navbar-dark bg-primary">
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto" id="app-menu">
              <li class="nav-item">
                <router-link to="/apiusage" class="nav-link pe-3 py-1 fs-5" :class="{active: selectedPath === '/apiusage'}">API Usage</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/assetexport" class="nav-link pe-3 py-1 fs-5" :class="{active: selectedPath === '/assetexport'}">Asset Export</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/emaildiff" class="nav-link pe-3 py-1 fs-5" :class="{active: selectedPath === '/emaildiff'}">Email Diff</router-link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
    <br />
    <router-view v-slot="{ Component }">
      <template v-if="cacheComponents">
        <keep-alive>
          <component :is="Component"></component>
        </keep-alive>
      </template>
      <template v-else>
        <component :is="Component"></component>
      </template>
    </router-view>
  </div>  
</template>

<script>
import TheAccessToken from './components/layout/TheAccessToken.vue'
import TheInstanceDropdown from './components/layout/TheInstanceDropdown.vue'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'
import { Auth } from 'aws-amplify'

export default {
  components: {
    TheAccessToken,
    TheInstanceDropdown
  },
  beforeCreate() { this.$store.commit('initializeStore');},
  computed: {
    selectedPath() {
      return this.$route.path
    },
    user() {
      return this.$store.getters['user']
    },
    authState() {
      return this.$store.getters['authState']
    }
  },
  async created() {
    this.unsubscribeAuth = onAuthUIStateChange((nextAuthState, authData) => {
        this.$store.commit('saveAuthState', nextAuthState)
        this.$store.commit('saveUser', authData)
        if (nextAuthState === AuthState.SignedIn) {
          if (!this.gettingInstances) {
            this.gettingInstances = true
            console.log("user successfully signed in!")
            this.$store.dispatch('getMarketoInstances')
            this.$router.push('/')
          } else {
            console.log("ignoring duplicate event")
          }
        } else {
          console.log("user is not signed in")
        }
    });
    if(this.authState === undefined) {
      Auth.currentAuthenticatedUser().then(authData => {
        this.$store.commit('saveAuthState', 'signedin')
        this.$store.commit('saveUser', authData)
        this.$store.dispatch('getMarketoInstances')
    });
    }
  },
  data() {
    return {
      unsubscribeAuth: undefined,
      gettingInstances: false,
      cacheComponents: true
    }
  },
  methods: {
    async signOut() {
      await Auth.signOut()
      this.$store.commit('saveAuthState', 'signedout')
      this.$store.commit('saveUser', null)
      this.$store.commit('saveMarketoInstances', [])
      this.cacheComponents = false  // make sure any cached results are wiped out
      if (this.selectedPath === '/instances') {
        this.$router.push('/')
      }
    }
  },
  watch: {
    selectedPath() {
      // set component caching back to true whenever user navigates
      this.cacheComponents = true
    }
  },
  beforeUnmount() {
    this.unsubscribeAuth();
  }
}
</script>

<style scoped>
h1 a {
  margin: 0;
  font-weight: bold;
  color: white;
  text-decoration: none;
}

h1 a:hover {
  color: rgba(255,255,255,.8);
}

h1 a:active {
  color: rgba(255,255,255,.55);
}

#app-menu {
  font-weight: 600;
}

</style>