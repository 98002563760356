import { createStore } from 'vuex'

import emailDiffModule from './modules/emailDiff/index.js'
import { Auth } from 'aws-amplify'
import axios from 'axios'

function getCookie(cookieName) {
  let name = cookieName + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function saveCookie(cookieName, cookieValue, expiresInDays = 30) {
  var dt = new Date();
  dt.setDate(dt.getDate() + expiresInDays );
  document.cookie = cookieName + "=" + cookieValue + "; expires=" + dt.toUTCString() ;
}

const store = createStore({
  modules: {
    emailDiff: emailDiffModule
  },
  state() {
    return {
      munchkinId: '',
      accessToken: '',
      recaptchaToken: '',
      user: undefined,
      authState: undefined,
      marketoInstances: [],
      instanceLoadComplete: false,
      selectedInstance: undefined
    };
  },
  getters: {
    munchkinId(state) {
      return state.munchkinId
    },
    munchkinIdIsValid(state) {
      return /^[0-9]{3}-[a-zA-Z]{3}-[0-9]{3}$/.test(state.munchkinId)
    },
    accessToken(state){
      return state.accessToken
    },
    accessTokenIsValid(state) {
      return /^[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}:/.test(state.accessToken)
    },
    recaptchaToken(state) {
      return state.recaptchaToken
    },
    user(state) {
      return state.user
    },
    authState(state) {
      return state.authState
    },
    marketoInstances(state) {
      return state.marketoInstances
    },
    instanceLoadComplete(state) {
      return state.instanceLoadComplete
    },
    selectedInstance(state) {
      // we are first checking that the selected instance exists in the 'marketoInstances' array
      if (state.selectedInstance && state.marketoInstances.find(instance => instance['id'] === state.selectedInstance)) {
        return state.selectedInstance
      } else if (!state.selectedInstance && state.marketoInstances.length > 0) {
        // if no previously selected instance, select the first instance
        state.selectedInstance = state.marketoInstances[0]['id']
        return state.selectedInstance
      } else {
        return undefined
      }
    }
  },
  mutations: {
    initializeStore(state) {
      state.munchkinId = getCookie('munchkinId')
      state.accessToken = getCookie('accessToken')
      state.selectedInstance = getCookie('selectedInstance')
    },
    saveMunchkinId(state, munchkinId) {
      saveCookie('munchkinId', munchkinId)
      state.munchkinId = munchkinId
    },
    saveAccessToken(state, accessToken) {
      saveCookie('accessToken', accessToken, 1)
      state.accessToken = accessToken
    },
    saveRecaptchaToken(state, token) {
      state.recaptchaToken = token
    },
    saveUser(state, user) {
      state.user = user
    },
    saveAuthState(state, authState) {
      state.authState = authState
    },
    saveMarketoInstances(state, marketoInstances) {
      state.marketoInstances = marketoInstances
    },
    instanceLoadComplete(state, trueOrFalse) {
      state.instanceLoadComplete = trueOrFalse
    },
    addMarketoInstance(state, newMarketoInstance) {
      state.marketoInstances.push(newMarketoInstance)
    },
    updateMarketoInstance(state, marketoInstance) {
      const myInstance = state.marketoInstances.find(instance => instance.id === marketoInstance.id)
      const index = state.marketoInstances.indexOf(myInstance)
      if (index > -1) {
        state.marketoInstances[index] = marketoInstance
      }
    },
    deleteMarketoInstance(state, instanceId) {
      const myInstance = state.marketoInstances.find(instance => instance.id === instanceId)
      const index = state.marketoInstances.indexOf(myInstance)
      if (index > -1) {
        state.marketoInstances.splice(index, 1)
      }
    },
    saveSelectedInstance(state, instanceId) {
      if (instanceId){
        saveCookie('selectedInstance', instanceId)
      }
      state.selectedInstance = instanceId
    }
  }, 
  actions: {
    requestRecaptchaToken(context) {
      return new Promise((resolve) => {
          const recaptchaSiteKey = '6Lf8_oMbAAAAAPb7spDeKHf8bjCxw8GOmusXtm03'
          // eslint-disable-next-line no-undef
          grecaptcha.ready(async () => {
              // eslint-disable-next-line no-undef
              const token = await grecaptcha.execute(recaptchaSiteKey)
              context.commit('saveRecaptchaToken', token)
          resolve(token);
          });
      });
    },
    async getMarketoInstances(context) {
      context.commit('instanceLoadComplete', false)
      const idToken = (await Auth.currentSession()).getIdToken().getJwtToken()
      var axiosConfig = {headers: {authorization: idToken}};
      const res = await axios.get('https://' + process.env.VUE_APP_API_GATEWAY + '.execute-api.us-east-2.amazonaws.com/marketo_instance', axiosConfig)
      context.commit('saveMarketoInstances', res.data.result)
      context.commit('instanceLoadComplete', true)
    }
  }
})

export default store
