<template>
    <div class="row">
      <div class="col-md-6">
        <h2>Manage Marketo Instances</h2>
        <div v-if="marketoInstances.length > 0">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Munchkin ID</th>
                        <th scope="col">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="instance in marketoInstances" :key="instance.id">
                        <td>{{ instance.name }}</td>
                        <td>{{ instance.munchkinId }}</td>
                        <td><a href="#" @click="populateForm(instance)">Edit</a> | <a href="#" @click="deleteInstance(instance)">Delete</a></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-else-if="instanceLoadComplete">
            <div class="alert alert-warning" role="alert">
              No Marketo Instances configured yet. Please add your API details here: 
            </div>
        </div>
        <button class="btn btn-primary" @click="newInstance" :disabled="disableAddButton">Add Marketo Instance</button>
      </div>
      <div class="col-md-6" v-if="editMode || (marketoInstances.length === 0 && instanceLoadComplete)" style="background-color: #d3d3d3; padding: 15px; border-radius: 5px;">
        <form @submit.prevent="createUpdateInstance">
          <div class="form-group row my-2">
            <label for="name_field" class="col-sm-3 col-form-label">Name *</label>
            <div class="col-sm-9">
              <input type="text" class="form-control" id="name_field" placeholder="ex. Acme Inc Production (any name up to 100 chars)" 
                     required maxlength="100" v-model.trim="editInstance.name">
            </div>
          </div>
          <div class="form-group row my-2">
            <label for="munchkin_id_field" class="col-sm-3 col-form-label">Munchkin ID *</label>
            <div class="col-sm-9">
              <input type="text" class="form-control" id="munchkin_id_field" placeholder="ex. 000-AAA-111" 
                     required v-model.trim="editInstance.munchkinId" @blur="checkMunchkinIdOnBlur" @input="checkMunchkinIdOnInput"
                     v-bind:class="{ 'is-invalid': !munchkinIdLooksGood }"> 
              <div class="invalid-feedback mb-2">Incorrect Munchkin ID, expected format is 111-AAA-222</div>
            </div>
          </div>
          <div class="form-group row my-2">
            <label for="client_id_field" class="col-sm-3 col-form-label">Client ID *</label>
            <div class="col-sm-9">
              <input type="text" class="form-control" id="client_id_field" placeholder="ex. 7cf6a683-8c6a-4d30-b2b1-c9f17432d063" 
                     required v-model.trim="editInstance.clientId" @blur="checkClientIdOnBlur" @input="checkClientIdOnInput"
                     v-bind:class="{ 'is-invalid': !clientIdLooksGood }">
              <div class="invalid-feedback mb-2">Incorrect Client ID, expected format is 7cf6a683-8c6a-4d30-b2b1-c9f17432d063</div>
            </div>
          </div>      
            <div class="form-group row my-2">
            <label for="client_secret_field" class="col-sm-3 col-form-label">Client Secret <span v-if="!('id' in editInstance)">*</span></label>
            <div class="col-sm-9">
              <div class=" input-group">
                <input 
                  :type="passwordFieldType" class="form-control" id="client_secret_field" 
                  :placeholder="('id' in editInstance) ? 'optional' : 'ex. vJ9VrJefvHMps55YQQb4K5ypr9Xrn2Hg'" 
                  :required="('id' in editInstance) ? false : true" 
                  v-model.trim="editInstance.clientSecret"
                  @blur="checkClientSecretOnBlur" @input="checkClientSecretOnInput"
                  v-bind:class="{ 'is-invalid': !clientSecretLooksGood }">
                <a href="#" @click="togglePassword" class="input-group-text bi" 
                   :class="passwordFieldType === 'password' ? 'bi-eye-slash' : 'bi-eye'" style="text-decoration: none;"></a>
                <div class="invalid-feedback mb-2">Incorrect Client Secret, 32 characters expected</div>
              </div>
            </div>
          </div> 
          <div v-if="errorMessage" style="color:red;">{{ errorMessage }}</div>
          <div class="form-group row  mt-3">
            <div class="col-sm-10">
              <button type="submit" class="btn btn-primary" :disabled="disableSaveButton">Save</button>
              &nbsp;&nbsp;<a href="#" @click="cancelSave">Cancel</a>
            </div>
          </div>
        </form>
        <p class="mt-4">Please refer to the <a href="https://developers.marketo.com/rest-api/" target="_new">Marketo Documentation</a> on how
        to get a Client ID and Client Secret. As a best practice, please create a dedicated API User, API Role &amp; LaunchPoint Service for Dataketo.
        When creating the API Role, select the <strong>"Asset Read-Only"</strong> permission . </p>
      </div>
    </div>
</template>

<script>
import { Auth } from 'aws-amplify'
import axios from 'axios'
import { parse as uuidParse } from 'uuid'

export default {
  data() {
    return {
      user: undefined,
      authState: undefined,
      unsubscribeAuth: undefined,
      editMode: false,
      editInstance: {},
      disableSaveButton: false,
      disableAddButton: false,
      munchkinIdLooksGood: true,
      munchkinIdInitialInputComplete: false,
      clientIdLooksGood: true,
      clientIdInitialInputComplete: false,
      clientSecretLooksGood: true,
      clientSecretInitialInputComplete: false,
      errorMessage: '',
      passwordFieldType: 'password'
    }
  },
  methods: {
    async createUpdateInstance() {
        this.disableSaveButton = true
        this.errorMessage = ''
        const idToken = (await Auth.currentSession()).getIdToken().getJwtToken()
        var axiosConfig = {
            headers: {authorization: idToken}
        };
        let res
        if ('id' in this.editInstance) {
          // update
          const instance_id = this.editInstance['id']
          const copiedInstance = JSON.parse(JSON.stringify(this.editInstance))
          delete copiedInstance.id
          try {
            res = await axios.put('https://' + process.env.VUE_APP_API_GATEWAY + '.execute-api.us-east-2.amazonaws.com/marketo_instance/' + instance_id, copiedInstance, axiosConfig)
            this.$store.commit('updateMarketoInstance', res.data.result)
            this.editMode = false
            this.disableSaveButton = false 
          } catch (error) {
            this.errorMessage = error.response.data.error
            this.disableSaveButton = false
          }
        } else {
          // create
          try {
            res = await axios.post('https://' + process.env.VUE_APP_API_GATEWAY + '.execute-api.us-east-2.amazonaws.com/marketo_instance', this.editInstance, axiosConfig)
            this.$store.commit('addMarketoInstance', res.data.result)
            this.editMode = false
            this.disableSaveButton = false 
            this.disableAddButton = false
            if (this.marketoInstances.length === 1) {
              this.$store.commit('saveSelectedInstance', res.data.result.id)
            }
          } catch (error) {
            this.errorMessage = error.response.data.error
            this.disableSaveButton = false
          }
        }
    },
    checkMunchkinIdOnBlur() {
      if (this.editInstance.munchkinId) {
        if (!this.munchkinIdIsValid) {
          this.munchkinIdLooksGood = false
        } else {
          this.munchkinIdLooksGood = true
        }
        this.munchkinIdInitialInputComplete = true // now we validate on each keystroke
      }
    },
    checkMunchkinIdOnInput() {
      // once field has initially been filled out, check on every keystroke
      if (this.munchkinIdInitialInputComplete) {
        this.checkMunchkinIdOnBlur()
      }
    },
    checkClientIdOnBlur() {
      if (this.editInstance.clientId) {
        if (!this.clientIdIsValid) {
          this.clientIdLooksGood = false
        } else {
          this.clientIdLooksGood = true
        }
        this.clientIdInitialInputComplete = true // now we validate on each keystroke
      }
    },
    checkClientIdOnInput() {
      if (this.clientIdInitialInputComplete) {
        this.checkClientIdOnBlur()
      }
    },
    checkClientSecretOnBlur() {
      if (this.editInstance.clientSecret) {
        if (!this.clientSecretIsValid) {
          this.clientSecretLooksGood = false
        } else {
          this.clientSecretLooksGood = true
        }
        this.clientSecretInitialInputComplete = true // now we validate on each keystroke
      }
    },
    checkClientSecretOnInput() {
      if (this.clientSecretInitialInputComplete) {
        this.checkClientSecretOnBlur()
      }
    },
    async deleteInstance(instance) {
      if(confirm('Do you really want to delete "' + instance.name + '"?')){
        const idToken = (await Auth.currentSession()).getIdToken().getJwtToken()
        var axiosConfig = {
            headers: {authorization: idToken}
        };
        const res = await axios.delete('https://' + process.env.VUE_APP_API_GATEWAY + '.execute-api.us-east-2.amazonaws.com/marketo_instance/' + instance.id, axiosConfig)
        this.$store.commit('deleteMarketoInstance', instance.id)
        console.log(res)
        console.log(res.data.result)
      }
    },
    populateForm(instance) {
      this.disableAddButton = false  // just in case someone switches from "add" to "edit" mode
      this.errorMessage = ''
      const currentInstance = {}
      for (const [key, value] of Object.entries(instance)) {
        currentInstance[key] = value
      }
      this.editInstance = currentInstance
      this.editMode = true
    },
    newInstance() {
      this.disableAddButton = true
      this.disableSaveButton = false
      this.errorMessage = ''
      this.editInstance = {}
      this.editMode = true
    },
    cancelSave() {
      this.disableAddButton = false
      this.editMode = false
      this.errorMessage = ''
    },
    togglePassword() {
      this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password"
    }
  },
  computed: {
    marketoInstances() {
      return this.$store.getters['marketoInstances']
    },
    munchkinIdIsValid() {
      return /^[0-9]{3}-[a-zA-Z]{3}-[0-9]{3}$/.test(this.editInstance.munchkinId)
    },
    clientIdIsValid() {
      try {
        uuidParse(this.editInstance.clientId)
        return true
      } catch (error) {
        return false
      }
    },
    clientSecretIsValid() {
      if (!this.editInstance.clientSecret){
        return false
      } else {
        return (this.editInstance.clientSecret.length === 32)
      }  
    },
    instanceLoadComplete() {
        return this.$store.getters['instanceLoadComplete']
    }
  }
}
</script>
