<template>
    <div class="row my-1">
      <div class="col-md-5 my-0">
        <div class="form-floating">
          <input type="text" 
                  class="form-control" 
                  id="munchkin_id_field" 
                  placeholder="ex. 000-AAA-111" 
                  v-model.trim="munchkinId" 
                  @blur="checkMunchkinId" 
                  @input="checkMunchkinIdInvalid"
                  required
                  v-bind:class="{ 'is-invalid': !munchkinIdLooksGood }"> 
          <label for="munchkin_id_field">Munchkin ID</label>
          <div v-if="!munchkinIdLooksGood" class="invalid-feedback" style="color:white;">Incorrect Munchkin ID, expected format is 111-AAA-222</div>
        </div>
      </div>
      <div class="col-md-7 my-0">
        <div class="form-floating">
          <input type="text" 
                  class="form-control" 
                  id="access_token_field" 
                  placeholder="ex. 51197893-fb8d-45f7-b820-fe31c18a961a:sj" 
                  v-model.trim="accessToken"
                  @blur="checkAccessToken"
                  @input="checkAccessTokenInvalid"
                  required 
                  v-bind:class="{ 'is-invalid': !accessTokenLooksGood }">
          <label for="access_token_field">Access Token</label>
          <div v-if="!accessTokenLooksGood" class="invalid-feedback" style="color:white;">Incorrect Access Token, expected format is 51197893-fb8d-45f7-b820-fe31c18a961a:sj</div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      munchkinIdLooksGood: true,
      munchkinIdInitialInputComplete: false,
      accessTokenLooksGood: true,
      accessTokenInitialInputComplete: false
    }
  },
  computed: {
    munchkinId:  {
      get () {
          return this.$store.state.munchkinId
      },
      set (value) {
          this.$store.commit('saveMunchkinId', value)
      }
    },
    accessToken:  {
      get () {
          return this.$store.state.accessToken
      },
      set (value) {
          this.$store.commit('saveAccessToken', value)
      }
    },
    munchkinIdIsValid() {
          return this.$store.getters['munchkinIdIsValid']
      },
    accessTokenIsValid() {
          return this.$store.getters['accessTokenIsValid']
      }
  },
  methods: {
    checkMunchkinId() {
      if (this.munchkinId) {
        if (!this.munchkinIdIsValid) {
          this.munchkinIdLooksGood = false
        } else {
          this.munchkinIdLooksGood = true
        }
        this.munchkinIdInitialInputComplete = true // now we validate on each keystroke
      }
    },
    checkMunchkinIdInvalid() {
      // once field has initially been filled out, check on every keystroke
      if (this.munchkinIdInitialInputComplete) {
        this.checkMunchkinId()
      }
    },
    checkAccessToken() {
      if (this.accessToken) {
        if (!this.accessTokenIsValid) {
          this.accessTokenLooksGood = false
        } else {
          this.accessTokenLooksGood = true
        }
        this.accessTokenInitialInputComplete = true // now we validate on each keystroke
      }
    },
    checkAccessTokenInvalid() {
      // once field has initially been filled out, check on every keystroke
      if (this.accessTokenInitialInputComplete) {
        this.checkAccessToken()
      }
    }
  } 
}
</script>
