<template>
    <div id="faq" class="row">
        <h2 class="mb-3">FAQ &amp; Help</h2>
        <h4>What does Dataketo do?</h4>
        <p class="mb-1">Dataketo offers Apps for Marketo Power Users:</p>
        <div class="mt-1">
        <ol> 
          <li>Asset Export: export Asset Metadata in CSV format from your Marketo instance. This is useful if you use 
          Excel/Sheets to search through your assets, sort them, filter them or report on them.</li> 
          <li>Email Diff: show the difference (Diff) between an 
          approved and a draft version of the same Email. Based on the diff, you can decide to discard the draft or approve it. </li>
        </ol>
        </div>
        <h4>Is Dataketo free?</h4>
        <p>
          At this time, Dataketo is free to use. 
        </p>
        <h4>Do I need to create a Login?</h4>
        <p>
          No, creating a login is convenient but optional. You can also enter your Munchkin ID and an Access Token and use the Dataketo Apps
          for as long as the token is valid (usually 1 hour). You can generate an Access Token by going to <i>Marketo Admin > 
          Launchpoint > (select an API user) > View Details > Get Token</i>. If you prefer to create a login, use the "Sign In/Sign Up"
          in the top right and select "create account". Once you're logged in, you can add credentials for one or more Marketo instances.
        </p>
        <h4>Does this count against my Marketo API Quota?</h4>
        <p>Yes, Dataketo uses the Marketo REST API and its API calls count against your daily quota. However, we download up to 200 assets per
          API call, so API usage is typically pretty low. Email Diff uses 4 API calls for each Diff. 
        </p>
        <h4>What permissions does my API User need?</h4>
        <p>
          The API Role associated with your API User needs to have "Read-only Assets" permissions. As a best practice, we recommend 
          creating a dedicated API User for Dataketo. 
        </p>
        <h4>What about Security &amp; Privacy?</h4>
        <p>Security &amp; Privacy have been top considerations since the very first version of Dataketo. By default we encrypt all connections and we 
          encrypt all data that we (temporarily) store. Also, if we don't need data anymore, we will delete it. We do not log any of your data, we are 
          only logging aggregate information. We do not use cookies to track users, but we do store the Munchkin ID and Access Token in a cookie for 
          usability, for 1 month and 1 hour respectively (only if you use Munchkin ID &amp; Access Token).
          The login system is based on the proven infrastructure of AWS Cognito, which caches some user data in Local Storage. It will auto-login 
          returning users: if you don't want this, please Sign Out at the end of your session. Marketo Instance details are stored in an encrypted data 
          store. You can delete your Marketo Instance details at any time, which permanently removes them from our systems. To delete your account, 
          please <a href="mailto:dataketo@momentera.com">email us</a>. 
           </p>
        <h4>Who built this?</h4>
        <p>This service was developed by Jep Castelein, a former Marketo employee and currently a <a href="https://www.jepcastelein.com">freelance 
          Martech Architect</a>.</p>
        <h4>Provide feedback or have questions?</h4>
        <p>We'd love to hear from you! Please <a href="mailto:dataketo@momentera.com">email us</a>.</p>
        <h4>Legal Terms</h4>
        <p>Dataketo is provided "as-is", without warranty. In no event shall the author be liable for any claim.</p>
    </div>
</template>
