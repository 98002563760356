<template>
    <div class="col-md-4 ms-0 me-2 my-3" >
      <form @submit.prevent="startJob">
      <div class="row">
          <div class="col">
              <p>This app generates an API daily usage report for the REST API and the Bulk Extract API. The REST API is the same
              as the report in Marketo's UI, but the daily usage for the Bulk Extract API is not visible in the Marketo UI. You can 
              also copy and/or download this report. </p>
              <p><em>Unlike the REST API Usage, the Bulk Extract Usage is only for the API User that you are using to generate this report. </em></p>
          </div>
      </div>
      <div class="form-group row">
        <div class="col">
          <button type="submit" class="btn btn-primary" v-bind:disabled="disableButton">Create API Usage Report</button>
          <div v-if="munchkinOrTokenInvalid" class="text-danger mt-2">Please enter a correctly formatted Munchkin ID and Access Token above or sign in</div>
          <div v-if="noInstanceSelected" class="text-danger mt-2">Please select a Marketo instance (or add your first Marketo Instance)</div>
        </div>
      </div>
      <div class="form-group row" v-if="disableButton">
        <div class="col">
          <a @click="cancelJob" style="text-decoration: underline; cursor: pointer;">Cancel Export</a>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  emits: ['start'],
  data() {
        return {
            disableButton: false,
            munchkinOrTokenInvalid: false,
            noInstanceSelected: false
        }
  },
  computed: {
      munchkinId() {
          return this.$store.getters['munchkinId']
      },
      accessToken() {
          return this.$store.getters['accessToken']
      },
      munchkinIdIsValid() {
          return this.$store.getters['munchkinIdIsValid']
      },
      accessTokenIsValid() {
          return this.$store.getters['accessTokenIsValid']
      },
      selectedInstanceId() {
          return this.$store.getters['selectedInstance']
      },
      user() {
          return this.$store.getters['user']
      }
  },
  methods: {
    startJob() {
      if (!this.user) {
        if (this.munchkinIdIsValid && this.accessTokenIsValid){
          this.munchkinOrTokenInvalid = false
          this.disableButton = true
          this.$emit('start', this.munchkinId, this.accessToken, null)
        } else {
          this.munchkinOrTokenInvalid = true
        }
      } else {
        if (this.selectedInstanceId) {
          this.noInstanceSelected = false
          this.disableButton = true
          this.$emit('start', null, null, this.selectedInstanceId)
        } else {
          this.noInstanceSelected = true
        }
      }
    },
    cancelJob() {
      this.disableButton = false
    }
  }
}
</script>