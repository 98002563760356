<template>
    <div class="col-md m-3">
        <div v-if="resultScreenVisible" class="row" style="background-color: #d3d3d3; padding: 15px; border-radius: 5px;">
            <h3 style="margin-top: 5px;">API Usage Report 
                <span v-if="!jobDone">in Progress</span>
                <span v-if="jobDone && !errorMessage">Complete</span>
            </h3>
            <div v-if="!jobDone" class="spinner-border text-secondary m-3" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <div v-if="jobDone && !errorMessage" style="margin-top: 10px;">
                <p>The days are midnight-to-midnight in Central Time. Marketo's API quotas reset at midnight Central Time. </p>
                <div class="my-1">&nbsp;</div>
                <h5>REST API</h5>
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">User</th>
                            <th v-for="myDate in apiUsageResult.rest_api.dates" :key="myDate" scope="col">{{ myDate }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="apiUser in apiUsageResult.rest_api.users" :key="apiUser">
                            <td>{{ apiUser.userId }}</td>
                            <td v-for="col in apiUser.usage" :key="col">{{ col }}</td>
                        </tr>
                        <tr>
                            <th scope="row">Total</th>
                            <td v-for="totalCount in apiUsageResult.rest_api.total" :key="totalCount">{{ totalCount }}</td>
                        </tr>
                    </tbody>
                </table>
                <p>[<a :href="apiUsageResult.rest_api_url">Download Rest API Usage</a>]</p>
                <div class="my-3">&nbsp;</div>

                <h5>Bulk Extract API</h5>
                <p><em>Includes only the API user selected in Dataketo. If additional API users are using the Bulk Extract API, 
                    add them to Dataketo and run this report again. </em></p>
                <p v-if="missingObjects" style="color: red;">No Marketo API permissions for these objects: {{missingObjects}}</p>
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col" style="width: 200px;">Object</th>
                            <th v-for="myDate in apiUsageResult.bulk_api.dates" :key="myDate" scope="col">{{ myDate }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="myObject in apiUsageResult.bulk_api.objects" :key="myObject">
                            <td>{{ myObject.objectName }}</td>
                            <td v-for="col in myObject.usage" :key="col">{{ col }}</td>
                        </tr>
                        <tr>
                            <th scope="row">Total</th>
                            <td v-for="totalCount in apiUsageResult.bulk_api.total" :key="totalCount">{{ totalCount }}</td>
                        </tr>
                    </tbody>
                </table>
                <p>[<a :href="apiUsageResult.bulk_api_url">Download Bulk API Usage</a>] [<a :href="apiUsageResult.bulk_api_all_url">Download All Bulk API Jobs</a>] (times in Central Time)</p>
                <div class="my-3">&nbsp;</div>

                <h5>Bulk Jobs in Progress</h5>
                
                <div v-if="apiUsageResult.bulk_jobs_in_progress.length > 0">
                <p><em>All times are in Central Time. </em></p>
                <table  class="table" >
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Export ID</th>
                            <th>Status</th> 
                            <th>Created</th>
                            <th>Queued</th>
                            <th>Started</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="inProgress in apiUsageResult.bulk_jobs_in_progress" :key="inProgress.exportId">
                            <td>{{ inProgress.type }}</td>
                            <td>{{ inProgress.exportId }}</td>
                            <td>{{ inProgress.status }}</td>
                            <td>{{ inProgress.createdAt }}</td>
                            <td>{{ inProgress.queuedAt }}</td>
                            <td>{{ inProgress.startedAt }}</td>
                        </tr>
                    </tbody>
                </table>
                </div>
                <p v-else>None</p>
            </div>
            <div v-if="errorMessage" style="color:red;">{{ errorMessage }}</div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { Auth } from 'aws-amplify'

export default {
    emits: ['complete'],
    data() {
      return {
        errorMessage: '',
        munchkinId: '',
        accessToken: '',
        instanceId: '',
        resultScreenVisible: false,
        jobDone: false,
        apiUsageResult: null
      }
    },
    computed: {
        missingObjects() {
            const objectList = ['Activity', 'Lead', 'Custom Object', 'Program Member']
            const myMissingObjects = []
            for (let expectedObject of objectList) {
                let found = false
                for (let myObject of this.apiUsageResult.bulk_api.objects) {
                    if (myObject.objectName == expectedObject) {
                        console.log('found ' + myObject.objectName)
                        found = true;
                        break
                    }
                }
                if (!found) {
                    myMissingObjects.push(expectedObject)
                }
            }
            return myMissingObjects.join(', ')
        }
    },
    methods: {
        async startJob(munchkinId, accessToken, token, instanceId) {
            this.resultScreenVisible = true  // makes the results screen visible
            this.jobDone = false
            this.munchkin_id = munchkinId
            this.accessToken = accessToken
            this.instanceId = instanceId
            this.errorMessage = ''  // this will remove any error messages from prior attempts
            let pathPostfix = ''
            let axiosConfig
            let payload
            if (!this.instanceId) {
                payload = {
                    "munchkin_id": munchkinId, 
                    "access_token": accessToken,
                    "recaptcha_token": token
                }
            } else {
                pathPostfix = '_auth'
                const idToken = (await Auth.currentSession()).getIdToken().getJwtToken()
                axiosConfig = {
                    headers: {authorization: idToken}
                };
                payload = {
                    "recaptcha_token": token,
                    "instance_id": instanceId
                }
            }
            axios.post('https://' + process.env.VUE_APP_API_GATEWAY + '.execute-api.us-east-2.amazonaws.com/apiusage' + pathPostfix, payload, axiosConfig)
                .then(res => {
                    this.jobDone = true
                    if(res.data.success) {
                        let response = res.data.result
                        console.log(response)
                        this.apiUsageResult = response
                        this.enableButton()
                    } else {
                        console.log('error: ' + res.data.error)
                        this.enableButton()
                        this.errorMessage = res.data.error
                    }                
                })
                .catch(error => console.log(error))
        },
        enableButton() {
            this.$emit('complete')
        }
    }
}
</script>