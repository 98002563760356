<template>
    <div>
    <amplify-auth-container>
      <amplify-authenticator username-alias="email" initial-auth-state="signin">
        <amplify-sign-up slot="sign-up" username-alias="email" :formFields="formFields"></amplify-sign-up>
        <amplify-sign-in slot="sign-in" username-alias="email"></amplify-sign-in>
      </amplify-authenticator>
    </amplify-auth-container>
    <p class="text-center"><strong>Password requirements:</strong> between 12 and 99 characters; must include numbers, special characters and lower/upper case Latin letters<br />
    Allowed special characters: <code>^ $ * . [ ] { } ( ) ? " ! @ # % &amp; / \ , &gt; &lt; ' : ; | _ ~ `</code><br />
    To change your password, use the "Reset password" functionality.</p>
    </div>
</template>

<script>
export default {
  name: 'AuthWithSlots',
  data() {
    return {
      formFields: [
        {
          type: 'email',
          inputProps: { required: true, autocomplete: 'username' },
        },
        {
          type: 'password',
          inputProps: { required: true, autocomplete: 'new-password' },
        }
      ]
    }
  }
}
</script>

<style>
  amplify-authenticator {
    --container-height: 400px;
    --box-shadow: none;
    --container-align: top;
  }

  /* overriding Amplify's default orange colors to change the sign in/up button colors and link color */
  :root {
    --amplify-primary-color: #0d6efd;
    --amplify-primary-shade: #0a58ce;
    --amplify-primary-tint: #0d6efd50;
    --amplify-font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    --amplify-text-md-sub: 1.5rem;
  }
 
</style>