<template>
    <div class="col-md m-3">
        <div v-if="objectName" class="row" style="background-color: #d3d3d3; padding: 15px; border-radius: 5px;">
            <h3 style="margin-top: 5px;">{{ objectName }} Export <span v-if="!downloadUrl">Started</span><span v-if="downloadUrl">Complete</span></h3>
            <div v-if="recordCount">Exported {{ recordCount }} records. <span v-if="!downloadUrl && recordCount % 200 != 0">Converting to CSV now...</span></div>
            <div v-if="downloadUrl" style="margin-top: 10px;"><a :href="downloadUrl" @click="enableExportButton"><strong> DOWNLOAD HERE </strong></a></div>
            <div v-if="errorMessage" style="color:red;">{{ errorMessage }}</div>
            <the-stopwatch ref="stopwatch"></the-stopwatch>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { Auth } from 'aws-amplify'
import TheStopwatch from '../../components/TheStopwatch.vue'

export default {
    components: {
        TheStopwatch
    },
    emits: ['export-complete'],
    data() {
      return {
        executionId: '',
        recordCount: 0,
        downloadUrl: '',
        errorMessage: '',
        munchkinId: '',
        accessToken: '',
        objectName: '',
        instanceId: ''
      }
    },
    methods: {
        async startJob(munchkinId, accessToken, objectName, token, instanceId) {
            if (this.objectName) {
                this.$refs.stopwatch.reset()  // reset in case there were prior exports
            }
            this.munchkin_id = munchkinId
            this.accessToken = accessToken
            this.objectName = objectName
            this.instanceId = instanceId
            this.recordCount = 0  // this will remove record count from prior exports
            this.errorMessage = ''  // this will remove any error messages from prior attempts
            this.downloadUrl = ''  // this will remove the download link from prior attempts
            if (this.$refs.stopwatch) {
                this.$refs.stopwatch.start()  // only start it when it already exists (there was a prior export)
            }
            let pathPostfix = ''
            let axiosConfig
            let payload
            if (!this.instanceId) {
                payload = {
                    "munchkin_id": munchkinId, 
                    "access_token": accessToken,
                    "object_name": objectName,
                    "recaptcha_token": token
                    }
            } else {
                pathPostfix = '_auth'
                const idToken = (await Auth.currentSession()).getIdToken().getJwtToken()
                axiosConfig = {
                    headers: {authorization: idToken}
                };
                payload = {
                    "object_name": objectName,
                    "recaptcha_token": token,
                    "instance_id": instanceId
                }
            }
            axios.post('https://' + process.env.VUE_APP_API_GATEWAY + '.execute-api.us-east-2.amazonaws.com/job' + pathPostfix, payload, axiosConfig)
                .then(res => {
                    console.log(res)
                    if(res.data.success) {
                        this.executionId = res.data.execution_id
                        console.log('execution ID: ' + this.executionId)
                        this.downloadUrl = this.checkJobStatus(this.executionId)
                    } else {
                        console.log('error: ' + res.data.error)
                        this.errorMessage = res.data.error
                        this.$refs.stopwatch.stop()
                    }                
                })
                .catch(error => console.log(error))
        },
        checkJobStatus(executionId) {
            this.poll(() => {
                console.log('getting status')
                return axios.get('https://' + process.env.VUE_APP_API_GATEWAY + '.execute-api.us-east-2.amazonaws.com/job/' + executionId);
            }, 900000, 2000).then((res) => {
                console.log('status reponse: ' + JSON.stringify(res))
                if (res.status == 'complete') {
                    this.recordCount = res.record_count
                    this.downloadUrl = res.download_url
                } else if (res.status == 'error') {
                    this.errorMessage = res.error_message
                    this.$emit('export-complete')
                }                
                this.$refs.stopwatch.stop()
            }).catch((e) => {
                this.errorMessage = 'Sorry, the maximum export duration is 15 minutes. You must have lots of data!'
                this.$refs.stopwatch.stop()
                this.$emit('export-complete')
                console.log('export timed out: ' + e)
            });
        },
        poll(fn, timeout, interval) {
            var endTime = Number(new Date()) + (timeout || 2000);
            interval = interval || 100;

            var checkCondition = (resolve, reject) => { 
                var ajax = fn();
                // dive into the ajax promise
                ajax.then( (response) => {
                    // If the condition is met, we're done!
                    if (response.data.status == 'complete') {
                        resolve({'status': response.data.status, 'download_url': response.data.download_url, 'record_count': response.data.record_count})
                    } 
                    else if (response.data.status == 'error') {
                        resolve({'status': response.data.status, 'error_message': response.data.error_message})
                    }
                    // If the condition isn't met but the timeout hasn't elapsed, go again
                    else if (Number(new Date()) < endTime) {
                        console.log(response.data)
                        if (response.data.status == 'in progress' && response.data.record_count) {
                            this.recordCount = response.data.record_count
                        }
                        setTimeout(checkCondition, interval, resolve, reject);
                    }
                    // Didn't match and too much time, reject!
                    else {
                        reject(new Error('timed out for ' + fn + ': ' + arguments));
                    }
                });
            };
            return new Promise(checkCondition);
        },
        enableExportButton() {
            this.$emit('export-complete')
        }
    }
}
</script>