<template>
    <div class="row mx-0 my-3">
        <api-usage-controls @start="startJob" ref='controls'></api-usage-controls>
        <api-usage-results @complete="enableButton" ref="results"></api-usage-results>
    </div>
</template>

<script>
import ApiUsageControls from './ApiUsageControls.vue'
import ApiUsageResults from './ApiUsageResults.vue'

export default {
    name: "apiusage",
    components: {
        ApiUsageControls, 
        ApiUsageResults
    },
    data() {
        return {
            munchkinId: '',
            accessToken: '', 
            instanceId: ''
        }
    },
    methods: {
        async startJob(enteredMunchkinId, enteredAccessToken, enteredInstanceId) {
            this.munchkinId = enteredMunchkinId
            this.accessToken = enteredAccessToken
            this.instanceId = enteredInstanceId
            const token = await this.$store.dispatch('requestRecaptchaToken')
            this.$refs.results.startJob(this.munchkinId, this.accessToken, token, this.instanceId)
        },
        enableButton() {
            this.$refs.controls.cancelJob()
        }
    }
}
</script>
